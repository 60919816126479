import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./BackgroundSlider.css";

import { sliderData } from "./BackgroundSliderData";

const BackgroundSlider = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  const goToNext = () => {
    setIndex((currentIndex) => (currentIndex + 1) % sliderData.length);
  };

  const goToPrevious = () => {
    setIndex(
      (currentIndex) =>
        (currentIndex - 1 + sliderData.length) % sliderData.length
    );
  };

  return (
    <div className="slider-show">
      <div className="slider-wrapper">
        {sliderData.map((slide, i) => (
          <motion.div
            key={i}
            initial={{ opacity: 0 }}
            animate={{ opacity: i === index ? 1 : 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="slide"
          >
            <img
              src={slide.image}
              alt={`Slide ${i}`}
              className="image-slider"
            />
            <div className="content">
              <div className="content-inside">
                <h2>{slide.title}</h2>
                <p>{slide.body}</p>
              </div>
              <hr />
            </div>
          </motion.div>
        ))}
      </div>
      <div className="slider-controls">
        <button onClick={goToPrevious}>&lt;</button>
        <button onClick={goToNext}>&gt;</button>
      </div>
      <div className="slider-dots">
        {sliderData.map((_, i) => (
          <span
            key={i}
            className={`dot ${i === index ? "active" : ""}`}
            onClick={() => setIndex(i)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default BackgroundSlider;

/* <div className="slidershow">
      <div
        className="slider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {sliderData.map((slide, index) => (
          <div className="image_slider" key={index}>
            <img src={slide.image} alt="" className="image" />
            <div className="content">
              <div className="content-inside">
                <h2>{slide.title}</h2>
                <p>{slide.body}</p>
              </div>
              <hr />
            </div>
          </div>
        ))}
      </div>
    </div> */

/* import React, { useEffect, useState, useRef } from "react";
import "./BackgroundSlider.css";

import { sliderData } from "./BackgroundSliderData.jsx";

const BackgroundSlider = () => {
  const delay = 3000;
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === sliderData.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="slidershow">
      <div
        className="slider"
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {sliderData.map((slide, index) => (
          <div className="image_slider" key={index}>
            <img src={slide.image} alt="" className="image" />
            <div className="content">
              <div className="content-inside">
                <h2>{slide.title}</h2>
                <p>{slide.body}</p>
              </div>
              <hr />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BackgroundSlider; */
