import { motion } from "../../../node_modules/framer-motion/dist/framer-motion";

import { useInView } from "react-intersection-observer";
import { cardY } from "../Animations";
import "./Storitve.css";

function StoritveItems({ className, id, heading, text, img }) {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.2,
  });

  return (
    <motion.div
      ref={ref}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: inView ? 1 : 0, scale: inView ? 1 : 0.8 }}
      transition={{ duration: 0.5 }}
      variants={cardY}
      className={className}
      id={id}
    >
      <div className="storitev-image">
        <img src={img} alt="img" />
      </div>
      <div className="storitev-text">
        <h2>{heading}</h2>
        <div>
          <pre>{text}</pre>
        </div>
      </div>
    </motion.div>
  );
}

export default StoritveItems;
